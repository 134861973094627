export default [
  // {
  //   label: 'Available on Online Store',
  //   field: 'published',
  //   type: {
  //     name: 'radio',
  //   },
  //   model: '',
  //   values: [
  //     {
  //       name: 'Yes',
  //       value: true,
  //     },
  //     {
  //       name: 'No',
  //       value: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Available on Search Engine, Sitemap files',
  //   field: 'published',
  //   type: {
  //     name: 'radio',
  //   },
  //   model: '',
  //   values: [
  //     {
  //       name: 'Yes',
  //       value: true,
  //     },
  //     {
  //       name: 'No',
  //       value: false,
  //     },
  //   ],
  // },
  {
    label: 'Collections',
    field: 'collectionIds',
    type: {
      name: 'autocomplete',
      mutiple: true,
      text: 'title',
      value: '_id',
    },
    resourceType: 'collections',
    items: [],
    model: null,
    nullType: true,
  },

  // {
  //   label: 'Variant price',
  //   field: 'price',
  //   fieldCompare: 'price',
  //   type: {
  //     name: 'compare',
  //     number: true,
  //   },
  //   model: '',
  //   compare: '',
  //   values: [
  //     {
  //       name: 'Equals to',
  //       value: 'equal',
  //     },
  //     {
  //       name: 'More than',
  //       value: 'greaterThan',
  //     },
  //     {
  //       name: 'Less than',
  //       value: 'lessThan',
  //     },
  //   ],
  // },
  // {
  //   label: 'Custom option',
  //   field: 'customOption',
  //   type: {
  //     name: 'radio',
  //   },
  //   model: '',
  //   values: [
  //     {
  //       name: 'Yes',
  //       value: true,
  //     },
  //     {
  //       name: 'No',
  //       value: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Image',
  //   field: 'hasImage',
  //   type: {
  //     name: 'radio',
  //   },
  //   model: '',
  //   values: [
  //     {
  //       name: 'Has image',
  //       value: true,
  //     },
  //     {
  //       name: `Doesn't have image`,
  //       value: false,
  //     },
  //   ],
  // },
  {
    label: 'Product type',
    field: 'productType',
    type: {
      name: 'autocomplete',
      mutiple: true,
      text: 'title',
      value: '_id',
    },
    resourceType: 'productType',
    items: [],
    model: null,
    nullType: true,
  },
  {
    label: 'Product tags',
    field: 'tags',
    type: {
      name: 'autocomplete',
      mutiple: true,
      text: 'title',
      value: '_id',
    },
    resourceType: 'tags',
    items: [],
    model: null,
    nullType: true,
  },
  {
    label: 'Title',
    field: 'title',
    fieldCompare: 'titleMode',
    type: {
      name: 'compare',
      number: false,
    },
    model: '',
    compare: '',
    values: [
      {
        name: 'Contains',
        value: 'contain',
      },
      {
        name: `Doesn't contain`,
        value: 'notContain',
      },
    ],
  },
  // {
  //   label: 'Variant barcode',
  //   field: 'barcode',
  //   fieldCompare: 'barcodeMode',
  //   type: {
  //     name: 'compare',
  //     number: false,
  //   },
  //   model: '',
  //   compare: '',
  //   values: [
  //     {
  //       name: 'Contains',
  //       value: 'contain',
  //     },
  //     {
  //       name: `Doesn't contain`,
  //       value: 'notContain',
  //     },
  //   ],
  // },
  // {
  //   label: 'Variant compare at price',
  //   field: 'compareAtPrice',
  //   fieldCompare: 'compareAtPriceMode',
  //   type: {
  //     name: 'compare',
  //     number: true,
  //   },
  //   model: '',
  //   compare: '',
  //   values: [
  //     {
  //       name: 'Equals to',
  //       value: 'equal',
  //     },
  //     {
  //       name: `More than`,
  //       value: 'greaterThan',
  //     },
  //     {
  //       name: `Less than`,
  //       value: 'lessThan',
  //     },
  //   ],
  // },
  // {
  //   label: 'Variant image',
  //   field: 'hasVariantImage',
  //   type: {
  //     name: 'radio',
  //   },
  //   model: '',
  //   values: [
  //     {
  //       name: 'Has image',
  //       value: true,
  //     },
  //     {
  //       name: `Doesn't have image`,
  //       value: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Variant inventory tracker',
  //   field: 'inventoryTracking',
  //   type: {
  //     name: 'radio',
  //   },
  //   model: '',
  //   values: [
  //     {
  //       name: 'Has image',
  //       value: true,
  //     },
  //     {
  //       name: `Doesn't have image`,
  //       value: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Variant Title',
  //   field: 'varintTitle',
  //   fieldCompare: 'varintTitleMode',
  //   type: {
  //     name: 'compare',
  //     number: false,
  //   },
  //   model: '',
  //   compare: '',
  //   values: [
  //     {
  //       name: 'Contains',
  //       value: 'contain',
  //     },
  //     {
  //       name: `Doesn't contain`,
  //       value: 'notContain',
  //     },
  //   ],
  // },
  // {
  //   label: 'Variant inventory quantity',
  //   field: 'inventoryQuantity',
  //   fieldCompare: 'inventoryQuantityMode',
  //   type: {
  //     name: 'compare',
  //     number: true,
  //   },
  //   model: '',
  //   compare: '',
  //   values: [
  //     {
  //       name: 'Equals to',
  //       value: 'equal',
  //     },
  //     {
  //       name: `More than`,
  //       value: 'greaterThan',
  //     },
  //     {
  //       name: `Less than`,
  //       value: 'lessThan',
  //     },
  //   ],
  // },
  // {
  //   label: 'Variant SKU',
  //   field: 'varintTitle',
  //   type: {
  //     name: 'compare',
  //     number: false,
  //   },
  //   model: '',
  //   compare: '',
  //   values: [
  //     {
  //       name: 'Contains',
  //       value: 'contain',
  //     },
  //     {
  //       name: `Doesn't contain`,
  //       value: 'notContain',
  //     },
  //   ],
  // },
  {
    label: 'Vendor',
    field: 'vendor',
    type: {
      name: 'autocomplete',
      mutiple: true,
      text: 'title',
      value: '_id',
    },
    resourceType: 'vendor',
    items: [],
    model: null,
    nullType: true,
  },
];
