<template>
  <div class="page-products__products">
    <v-row no-gutters class="elevation-2 mt-2 page__table-container">
      <v-col cols="12">
        <Resource
          :selectStatus="select"
          :document="document"
          :filter-fields="null"
          :filter-data="filterData"
          :filter-callback="setFilterData"
          :searchable="searchableField"
          :columns="columns"
          :updateRouterCallback="getUpdateRouterData"
          :routeName="updateRouteName"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between">
        <limit-page :callback="setLimit" />
        <pagination :visible="0" :length="lengthPage" :page.sync="page"></pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import filter from '@/const/filters/product-filter';
import resourceListMixin from '@/mixins/resource-list';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      select: true,
      email: false,
      recovery: false,
      sms: false,
      resourceType: 'abcheckout',
      // page: 1,
      tabs: [
        { name: 'All', key: '' },
        // { name: 'Open', key: 'published' },
        // { name: 'Unfulfilled and partially fulfilled', key: 'unpublished' },
        // { name: 'Chargeback and inquiry', key: 'unpublisheds' },
      ],
      text:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testVuex: null,
      columns: {
        // Selectable: {
        //   blankLabel: true,
        //   kind: 'selectable',
        //   width: 1,
        // },

        CHECKOUT: {
          field: 'name',
          kind: 'checkoutAbandon',
        },
        Date: {
          field: 'created_at',
          kind: 'datetime',
        },
        'PLACED BY': {
          field: 'customer',
          kind: 'customerAbandoned',
        },
        'FIRST EMAIL STATUS': {
          field: 'firstEmailStatus',
          kind: 'text-status',
        },
        'SECOND EMAIL STATUS': {
          field: 'secondEmailStatus',
          kind: 'text-status',
        },
        'THIRD EMAIL STATUS': {
          field: 'thirdEmailStatus',
          kind: 'text-status',
        },
        // 'EMAIL STATUS': {
        //   field: 'firstEmailStatus',
        //   kind: 'text-status',
        // },
        // 'SMS STATUS': {
        //   field: 'smsStatus',
        //   kind: 'text-status',
        // },
        'RECOVERY STATUS': {
          field: 'recoveryStatus',
          kind: 'text-status',
        },
        Total: {
          kind: 'price',
          field: 'total',
          // blankLabel: true,
        },
      },
      searchableField: 'title_search',
      updateRouteName: 'website.abandoned.checkout',

      sortable: [],
      filter,
    };
  },
  created() {},
  // methods: {
  //   test() {
  //     console.log('tuanson');
  //   },
  // },
};
</script>
<style lang="scss">
.page-products__products {
  background: white;
}
</style>
